@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');
.banner{
    width: 100%;
    height: 650px;
    background: linear-gradient(to right,rgba(245,245,245,.8),rgba(245,245,245,.8)),url("https://d33wubrfki0l68.cloudfront.net/daac8858fd4a0ccea44f59dfd079c3c16c263f33/c157c/assets/svg/common-bg.svg");
    background-position: center;
    position: relative;
}
.banner-text{
  .banner-logo{
    width: 200px;
    height: 200px;
    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}
h1{
  font-family: Source Sans Pro;
  font-size: 3rem;
}
p{
  color: #333;
  font-weight: initial;
  font-size: 20px;
}
}
.typewriter {
    width: 21.5ch;
    font-family: Source Sans Pro;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid #212121;
    animation: cursor 1s step-start infinite, 
    text 5s steps(18) alternate infinite;
}

@keyframes cursor {
    0%, 100% { 
    border-color: #212121; 
  }
}

@keyframes text {
    0% { 
    width: 0;
  }
    100% { 
    width: 19ch;
  }
}

.SocialsIcon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  box-shadow: rgba(100,100,111,.2) 0 7px 29px 0;
  padding: 0.9rem;
  ul{
    li{
      font-size: 32px;
      }
    }
  }

.button-cv{
  color: #333;
  background: #facf0f;
  margin-top: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
  transition: transform .3s;
  padding: 15px 25px;
}

/* About */
.about{
  h1{
    font-family: Source Sans Pro;
  }
  p{
    color: #555;
    font-size: 19px;
    font-weight: 500;
    font-family: Source Sans Pro "sans-serif";
    line-height: 1.6;
  }
}
@keyframes scaler {
  0%,40%,to {
      transform: scale(1)
  }

  20% {
      transform: scale(1.05)
  }
}
.skill-card{
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 30px;
  margin-bottom: 30px;
  position: relative;
  background: #fff;
  border-radius: 4px;
  animation: scaler 2.3s ease-in-out infinite;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  -webkit-box-shadow: 0 23px 49px 0 rgba(17, 17, 17, 0.1);
  box-shadow: 0 23px 49px 0 rgba(17, 17, 17, 0.2);
  span{
    font-family: sans-serif;
    font-weight: 600;
  }
  
}
.skills-icon{
  width: 3rem;
  height: 3rem;
  padding: 2px;
}

.project{
  h1{
    font-family: Source Sans Pro;
  }
  .project-button{
  color: #333;
  background: #facf0f;
  margin-top: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
  transition: transform .3s;
  padding: 10px 15px;
  }
  .project-button:hover{
    background-color: white;
    color: black;
  }
}
.contact-me{
  width: 100%;
  height: 650px;
  background: linear-gradient(to right,rgba(245,245,245,.8),rgba(245,245,245,.8)),url("https://d33wubrfki0l68.cloudfront.net/daac8858fd4a0ccea44f59dfd079c3c16c263f33/c157c/assets/svg/common-bg.svg");
  background-position: center;
  position: relative;
}
.contact-form{
  h1{
    font-family: Source Sans Pro;

  }
  input{
    width: 100%;
    color: #333;
    padding: 15px;
    border: 1px solid #ebebeb;
    letter-spacing: 0;
    background: #f0f0f0;
    border-radius: 5px;
    font-weight: 600;
  }
  textarea{
    width: 100%;
    color: #333;
    padding: 15px;
    border: 1px solid #ebebeb;
    letter-spacing: 0;
    background: #f0f0f0;
    border-radius: 5px;
    font-weight: 600;
  }
}