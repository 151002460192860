@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');
.navbar{
    background-color: rgb(17, 17, 17);
    height: 100%;
    .logo{
       width: 5rem;
       height: 4rem;
       display: flex;
       align-items: center;
    }
    .navbar-brand{
        color: white;
        font-family: Source Sans Pro;  
    }
    .navbar-nav{
        .nav-item{
            .nav-link{
                color: white;
                font-family: Source Sans Pro;                ;
            }
        }
    }
    .checkbox {
        opacity: 0;
        position: absolute;
      }
      
      .checkbox-label {
        background-color: #111;
        width: 50px;
        height: 26px;
        border-radius: 50px;
        position: relative;
        padding: 5px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .fa-moon {color: #f1c40f;}
      
      .fa-sun {color: #f39c12;}
      
      .checkbox-label .ball {
        background-color: #fff;
        width: 22px;
        height: 22px;
        position: absolute;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        transition: transform 0.2s linear;
      }
      
      .checkbox:checked + .checkbox-label .ball {
        transform: translateX(24px);
      }
}
@media (max-width: 571px) {
    .navbar{
        .container{
            display: block;
            text-align: end;
        }
    }
    .navbar-collapse {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      z-index: 9999;
      padding: 20px;
      overflow-y: auto;
      transform: translateX(-100%);
      transition: transform 0.3s ease;
    }
    
    .navbar-toggler {
      position: relative;
      z-index: 10000;
    }
    
    .navbar-collapse.show {
      transform: translateX(0%);
    }
    div#navbarSupportedContent{
        justify-content: start;
        align-items: center;
        gap: 110px;
        padding-top: 100px;
    }

  }

  