@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
.footer{
    background-color: rgb(17, 17, 17);
    h3{
        font-family: Source Sans Pro;
        font-weight: 900;
        border-bottom: 2px solid white;
        display: inline-block;
        line-height: 35px;
    }
    p{
        font-family: Source Sans Pro;
        font-weight: 400;
    }
    .social-icons{
        ul{
            li{
                display: inline-block;
                a{
                    font-size: 25px;
                    padding: 5px;
                }
            }
        }
    }
}