@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Raleway', sans-serif;

}
a {
    color: inherit;
    text-decoration: none;
}

ul{
    padding: 0;
    li{
        list-style-type: none;
    }
}

.container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}
.appdark{
    color: white;
    background-color: black;
    .SocialsIcon{
        background-color: black;
    }
    .banner-text{
        color: black;
    }
    .about p{
        color: white;
    }
    .skill-card{
        span{
            color: black;
        }
    }
    .project{
        .card-body{
            color: white;
            background-color:  #0F0E0E;
        }
    }
    .contact-me{
        h1{
            color: black;
        }
        form{
            color: black;
        }
    }
}